<template>
<div>
  <Header/>
  <b-container 
    style="max-width:1400px; padding-top:70px;"
  >
    <div 
      class="row justify-content-center"
    >
      <div 
        class="col-md-7 col-lg-5 col-sm-8"
      >
        <div 
          class="card border-0 mb-0"
        >
          <div 
            class="card-body px-lg-5 py-lg-5"
          >
            <b-form>
              <b-row>
                <b-col 
                  sm="auto"
                >
                  <label 
                    for="loginId"
                  >
                    ログインID 
                  </label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="loginId"
                    v-model="$v.loginId.$model"
                    type="text"
                    @input="$v.loginId.$touch()"
                  />
                  <span 
                    v-if="$v.loginId.$error"
                  >
                    <small 
                      class="text-danger" 
                      v-if="!$v.loginId.required"
                    >
                      IDを入力してください
                    </small>
                    <small 
                      class="text-danger" 
                      v-if="!$v.loginId.alphaNum"
                    >
                      IDは半角英数字で入力してください
                    </small>
                  </span>
                </b-col>
              </b-row>

              <b-row 
                class="mt-4"
              >
                <b-col 
                  sm="auto"
                >
                  <label 
                    for="password"
                  >
                    パスワード
                  </label>
                </b-col>
                <b-col >
                  <b-form-input
                    id="password"
                    v-model="$v.password.$model"
                    type="password"
                    @input="$v.password.$touch()"
                  />
                  <span 
                    v-if="$v.password.$error"
                  >
                    <small 
                      class="text-danger" 
                      v-if="!$v.password.required"
                    >
                      パスワードを入力してください
                    </small>
                    <small 
                      class="text-danger" 
                      v-if="!$v.password.alphaNum"
                    >
                      パスワードは半角英数字で入力してください
                    </small>
                  </span>
                </b-col>
              </b-row>
                <small 
                  class="text-danger"
                >
                  {{ error }}
                </small>
              <b-row 
                class="mt-4"
              >
                <b-button 
                  class="w-100 " 
                  variant="success" 
                  @click="Login"
                >
                  ログイン
                </b-button>
              </b-row>
            </b-form>
            
          </div>
        </div>
      </div>
    </div>
  </b-container>
</div>
</template>

<script>
import Header from '../components/Header.vue'
import { required, alphaNum } from "vuelidate/lib/validators"

export default {
  title: 'ログイン',
  data() {
    return {
      loginId: '',
      password: '',
      error: ''
    }
  },
  components: {
    Header
  },
  methods: {
    //チケット#307ログイン機能の実装
    Login() {
      this.$v.$touch();
      if(!this.$v.$invalid) {
        this.axios.post('/api/v1/api', {
          params: {
            apiname: "login",
            loginId: this.loginId,
            password: this.password
          }
        })
        .then(res => { 
          //ステータスが200ならログイン成功。戻り値をセッションストレージ格納
          if(res.data.body.status === 200) {
            sessionStorage.setItem('userId', res.data.body.response[0].inUserID)
            sessionStorage.setItem('loginId', res.data.body.response[0].vcLoginID)
            sessionStorage.setItem('userName', res.data.body.response[0].vcUserLName)
            sessionStorage.setItem('userMail', res.data.body.response[0].vcMail)
            sessionStorage.setItem('userTell', res.data.body.response[0].cTEL)
            sessionStorage.setItem('role', res.data.body.response[0].tiRole)
            if(res.data.body.response[0].tiRole === 0) {
              this.$router.push('/facilityapproval')
            } else if(res.data.body.response[0].tiRole === 1) {
              this.$router.push('/photoform')
            } else if(res.data.body.response[0].tiRole >= 10 && res.data.body.response[0].tiRole <= 19 ) {
              this.$router.push('/autodistance')
            } else {
              this.$router.push('/mypage')
            }
            
          }else {
            this.error = res.data.body.response
          } })
        .catch(error => console.log(error));
      }
    }
  },

  validations: {
      loginId: {
        required,
        alphaNum
      },
      password: {
        required,
        alphaNum
      }
    }
}

</script>
