import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2';
import loadImage from 'blueimp-load-image'
import jQuery from 'jquery'
import { BootstrapVue, IconsPlugin ,FormTagsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css' ;
import 'bootstrap-vue/dist/bootstrap-vue.css';
import "./assets/scss/custom.scss";
import 'sweetalert2/dist/sweetalert2.min.css';
import Paginate from 'vuejs-paginate';
import Spinner from 'vue-simple-spinner';
import VueCarousel from 'vue-carousel';
import draggable from 'vuedraggable';
import VueScrollTo from 'vue-scrollto';
import pdf from 'vue-pdf';
import VCalendar from 'v-calendar';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import Datepicker from 'vuejs-datepicker';
import jszip from 'jszip';

const globalMixIn = {
  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      imgURL:"/photo/",
      resizeImgURL:"/resizephoto/",
      putImgURL:"/putphoto/",
      originalImgURL:"/exteriorphoto/",
      orginResizeURL:"/putexteriorphoto/",
      pdfURL:"/pdfurl/",
      resizeImgKey:"web/",
      putImgKey:"before_resize/",
      originalImgKey:"originalImg/",
      originResizeKey:"originResize/",
      noImage:'/static/images/noimage.jpg',
      googleMapLink:'https://www.google.com/maps?q=',
      gyozeroURL:'http://www2.gyo-pro.com:8080/Admin/Remst/Edit?preRemstID=',
      gyoproURL:'https://www.gyo-pro.com/Estate/Detail/',
      mobile:false,
      autoDistanceUser:false,
      adminUser:false,
      showLoader:false,
      showLoaderSecond:false,
      upIcon:'chevron-up',
      downIcon:'chevron-down',
      companyDocument:63,//社内資料
      flashReport:37,//マイソク
      wardMap:21,//区割り図
      guideMap:16,//案内図
      displayLimits:[
        {id:1, value:100, text:'表示件数:100件'},
        {id:2, value:200, text:'表示件数:200件'},
        {id:3, value:300, text:'表示件数:300件'},
        {id:4, value:400, text:'表示件数:400件'},
        {id:5, value:500, text:'表示件数:500件'},
        {id:6, value:1000, text:'表示件数:1000件'},
      ],
      reformStateCode:[
        {id:0,value:'無'},
        {id:1,value:'済'},
        {id:2,value:'完了予定'},
        {id:3,value:'その他'},
      ],
      estateStatus:[
        { id:'00', value:'公開' },
        { id:'01',	value:'留め' },
        { id:'02',	value:'済み' },
        { id:'03',	value:'削除' },
        { id:'04',	value:'未公開' },
        { id:'05',	value:'取り消し' },
        { id:'06',	value:'再販準備' },
        { id:'07',	value:'仕入' },
        { id:'08',	value:'完全削除' },
        { id:'09',	value:'非表示' },
      ],
      contactApproval:[
        {id:0,value:'未確認'},
        {id:1,value:'可'},
        {id:2,value:'不可'}
      ],
      introFlagValue:[
        {id:0,value:'未確認'},
        {id:1,value:'撮影可'},
        {id:2,value:'撮影不可'}
      ],
      shootProgress:[
        { id:1,value:'未撮影' },
        { id:2,value:'撮影済み' },
        { id:3,value:'確認済み' },
        { id:4,value:'撮影保留' },
        { id:5,value:'撮影不要' },
      ],
      selectProcesses:[
        { id:1,text:'変化なし',value:'変化なし' },
        { id:2,text:'造成中・古屋',value:'造成中・古屋' },
        { id:9,text:'更地(再撮影要)',value:'更地(再撮影要)' },
        { id:3,text:'更地',value:'更地' },
        { id:4,text:'基礎',value:'基礎' },
        { id:5,text:'上棟',value:'上棟' },
        { id:6,text:'シート',value:'シート' },
        { id:7,text:'外構',value:'外構' },
        { id:10,text:'完成(再撮影要)',value:'完成(再撮影要)' },        
        { id:8,text:'完成',value:'完成' },
      ],
      materialNo:[
        { id:80,value:'外構残し' },
        { id:83,value:'完成' }
      ],
      houseProcessNumbers:[
        { id:1,value:'リビング',orderNo:1,processNumber:112 },
        { id:1,value:'リビング',orderNo:2,processNumber:113 },
        { id:3,value:'居室(洋室)',orderNo:1,processNumber:114 },
        { id:3,value:'居室(洋室)',orderNo:3,processNumber:115 },
        { id:3,value:'居室(洋室)',orderNo:2,processNumber:116 },
        { id:7,value:'キッチン',orderNo:1,processNumber:117 },
        { id:9,value:'浴室',orderNo:1,processNumber:118 },
        { id:10,value:'トイレ',orderNo:1,processNumber:119 },
        { id:11,value:'洗面所',orderNo:1,processNumber:121 },
        { id:12,value:'玄関',orderNo:1,processNumber:122 },
        { id:22,value:'バルコニー',orderNo:1,processNumber:124 },
        { id:21,value:'眺望',orderNo:1,processNumber:125 },
        { id:26,value:'駐車場',orderNo:1,processNumber:126 },
        { id:15,value:'収納(ウォークインクローゼット)',orderNo:1,processNumber:127 },
        { id:17,value:'収納(シューズクローク)',orderNO:1,processNumber:128 },
        { id:14,value:'ロフト',orderNo:1,processNumber:129 },
        { id:18,value:'収納(床下)',orderNo:1,processNumber:130 },
        { id:25,value:'外観写真',orderNo:1,processNumber:131 },
        { id:27,value:'パノラマ',orderNo:1,processNumber:132 },
      ],
      mansionProcessNumbers:[
        { id:1,value:'リビング',orderNo:1,processNumber:70 },
        { id:3,value:'居室(洋室)',orderNo:1,processNumber:73 },
        { id:7,value:'キッチン',orderNo:1,processNumber:71 },
        { id:9,value:'浴室',orderNo:1,processNumber:72 },
        { id:10,value:'トイレ',orderNo:1,processNumber:74 },
        { id:11,value:'洗面所',orderNo:1,processNumber:75 },
        { id:12,value:'玄関',orderNo:1,processNumber:76 },
        { id:22,value:'バルコニー',orderNo:1,processNumber:79 },
        { id:21,value:'眺望',orderNo:1,processNumber:78 },
        { id:15,value:'収納(ウォークインクローゼット)',orderNo:1,processNumber:77 },
        { id:23,value:'庭',orderNo:1,processNumber:80 },
      ]
    }
  },
  created() {
    var { title, headerTitle } = this.$options
    if(title) {
      document.title = title;
    }
    if(headerTitle) {
      this.$store.dispatch('changeHeaderTitle', headerTitle);
    }
    if(window.innerWidth <= 400) {
      this.mobile = true
    } else {
      this.mobile = false
    }
    
  },

  mounted() {
    for(let i = 10;i <= 19;i++) {
      if(Number(sessionStorage.getItem('role')) === Number(i) || Number(sessionStorage.getItem('role')) === 0) {
        this.autoDistanceUser = true
        return
      } else {
        this.autoDistanceUser = false
      }
    }
    if(sessionStorage.getItem('role') === 0) {
      this.adminUser = true
    }
  }
  
}

global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')

Vue.component('datetimepicker', VueCtkDateTimePicker);
Vue.component('vue-timepicker', VueTimepicker)
Vue.component('datepicker',Datepicker)
Vue.component('pdf', pdf)
Vue.component('paginate', Paginate)
Vue.component('spinner', Spinner)
Vue.mixin(globalMixIn);
Vue.use(loadImage);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueCarousel);
Vue.use(FormTagsPlugin);
Vue.use(draggable);
Vue.use(jszip);
Vue.use(VueScrollTo,{
  duration: 50
});
Vue.use(VCalendar);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
