<template>
  <b-navbar
    id="header"
    toggleable="lg"
    type="dark"
    :variant="this.envClass"
    class="fixed-top top-0 navbar-expand-md mb-3 border-bottom border-white text-nowrap"
  >
    <b-container>
      <b-navbar-brand
        to="/mypage"
      >
        撮影管理システム
      </b-navbar-brand>
      <b-navbar-toggle
        target="nav-collapse"
      ></b-navbar-toggle>
      <b-collapse
        v-if="$route.path !== '/'"
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav>
          <b-nav-item-dropdown
            text="フォトサービス"
          >
            <b-dropdown-item
              to="/photoform"
            >
              写真登録
            </b-dropdown-item>
            <b-dropdown-item
              to="/facilitylist"
            >
              施設一覧
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown
            text="進捗管理"
          >
            <b-dropdown-item
              to="/shootscheduled"
            >
              撮影予定カレンダー
            </b-dropdown-item>
            <b-dropdown-item
              to="/progressmanagementlist"
            >
              進捗管理・納品
            </b-dropdown-item>
            <b-dropdown-item
              to="/introspectionlist"
            >
              内観写真管理
            </b-dropdown-item>
            <b-dropdown-item
              to="/areamanagement"
            >
              担当エリア編集
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown
            text="写真検品"
          >
            <b-dropdown-item
              to="/photoinspection"
            >
              一時検品ダウンロード
            </b-dropdown-item>
            <b-dropdown-item
              to="/photoupload"
            >
              検品写真アップロード
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item
            v-if="role == 0 || autoDistanceUser"
            to="/autodistance"
          >
            自動距離測
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav
          v-if="role == 0"
        >
          <b-nav-item-dropdown
            text="管理者項目"
          >
            <b-dropdown-item
              to="/facilityapproval"
            >
              施設承認一覧
            </b-dropdown-item>
            <b-dropdown-item
              to="/photoapproval"
            >
              施設写真承認一覧
            </b-dropdown-item>
            <b-dropdown-item
              to="/adminedit"
            >
              区分・タグ編集
            </b-dropdown-item>
            <b-dropdown-item
              to="/photosummarylist"
            >
              集計
            </b-dropdown-item>
            <b-dropdown-item
              to="/managementitem"
            >
              管理項目ダウンロード
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav
          style="margin-left:auto;"
        >
          <b-nav-item-dropdown
            :text="userName"
          >
            <b-dropdown-item
              to="/mypage"
            >
              マイページ
            </b-dropdown-item>
            <b-dropdown-item
              @click="Logout"
            >
              ログアウト
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>

</template>

<script>

export default {
  data() {
    return {
      userName: '',
      role: '',
      envClass:'dark'
    }
  },
  methods: {
    //ログアウト処理
    Logout() {
      sessionStorage.clear()
      this.$router.push('/')
    }
  },
  created() {
    if(sessionStorage.getItem('userName')) {
      this.userName = sessionStorage.getItem('userName')
      this.role = sessionStorage.getItem('role')
    }
    if(process.env.NODE_ENV === 'development'){
      this.envClass = 'danger'
    }
  },

}
</script>

<style scoped>

</style>
