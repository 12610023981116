import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter(to,form,next)  {
      if(sessionStorage.getItem('userId') !== null) {
        next('/mypage')
      }else {
        next()
      }
    }
  },
  {
    path: '/mypage',
    name: 'MyPage',
    component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage/myPage.vue')
  },
  {
    path: '/facilityapproval',
    name: 'facilityapproval',
    component: () => import(/* webpackChunkName: "facilityapproval" */ '../views/admin/FacilityApprovalPending.vue'),
    beforeEnter(to,form,next)  {
      if(sessionStorage.getItem('role') != 0) {
        next('/mypage')
      }else {
        next()
      }
    }
  },
  {
    path: '/photoapproval',
    name: 'PhotoApproval',
    component: () => import(/* webpackChunkName: "photoapproval" */ '../views/admin/PhotoApprovalPending .vue'),
    beforeEnter(to,form,next)  {
      if(sessionStorage.getItem('role') != 0) {
        next('/mypage')
      }else {
        next()
      }
    }
  },
  {
    path: '/adminedit',
    name: 'AdminEdit',
    component: () => import(/* webpackChunkName: "adminedit" */ '../views/admin/AdminEdit.vue'),
    beforeEnter(to,form,next)  {
      if(sessionStorage.getItem('role') != 0) {
        next('/mypage')
      }else {
        next()
      }
    }
  },
  {
    path: '/photosummarylist',
    name: 'PhotoSummaryList',
    component: () => import(/* webpackChunkName: "adminedit" */ '../views/admin/PhotoSummaryList.vue'),
    beforeEnter(to,form,next)  {
      if(sessionStorage.getItem('role') != 0) {
        next('/mypage')
        }else {
        next()
      }
    }
  },
  {
    path: '/managementitem',
    name: 'ManagementItem',
    component: () => import(/* webpackChunkName: "managementitem" */ '../views/admin/ManagementItemDownload.vue'),
    beforeEnter(to,form,next)  {
      if(sessionStorage.getItem('role') != 0) {
        next('/mypage')
        }else {
        next()
      }
    }
  },
  {
    path: '/facilitydetail/:id',
    name: 'FacilityDetail',
    component: () => import(/* webpackChunkName: "fascillitydetail" */ '../views/facility/FacilityDetail.vue')
  },
  {
    path: '/photoform',
    name: 'PhotoForm',
    component: () => import(/* webpackChunkName: "photoform" */ '../views/photo/PhotoForm.vue')
  },
  {
    path: '/facilitylist',
    name: 'FacilityList',
    component: () => import(/* webpackChunkName: "facilitylist" */ '../views/facility/FacilityList.vue')
  },
  { 
    path: '/facilityform',
    name: 'FacilityForm',
    component: () => import(/* webpackChunkName: "facilityform" */ '../views/facility/FacilityForm.vue')
  },
  {  
    path: '/updatefacilityform',
    name: 'UpdateFacilityForm',
    component: () => import(/* webpackChunkName: "updatefascillityform" */ '../views/facility/UpdateFacilityForm.vue')
  },
  {  
    path: '/autodistance',
    name: 'autoDistance',
    component: () => import(/* webpackChunkName: "autodistance" */ '../views/autodistance/AutoDistance.vue'),
  },
  {  
    path: '/exteriorform/:estateID/:locationID/:shootingType/:shootingDate/:typeID',
    name: 'ExteriorForm',
    component: () => import(/* webpackChunkName: "exteriorform" */ '../views/property/ExteriorForm.vue'),
  },
  {  
    path: '/propertyinsideform/:estateID/:locationID/:shootingType/:shootingDate/:userID',
    name: 'PropertyInsideForm',
    component: () => import(/* webpackChunkName: "propertyinsideform" */ '../views/property/PropertyInsideForm.vue'),
  },
  {  
    path: '/progressmanagementlist',
    name: 'progressManagementList',
    component: () => import(/* webpackChunkName: "progressmanagementlist" */ '../views/progress/ProgressManagementList.vue'),
  },
  {  
    path: '/introspectionlist',
    name: 'IntrospectionList',
    component: () => import(/* webpackChunkName: "introspectionlist" */ '../views/progress/IntrospectionList.vue'),
  },
  {  
    path: '/shootscheduled',
    name: 'ShootScheduled',
    component: () => import(/* webpackChunkName: "shootscheduled" */ '../views/progress/ShootScheduled.vue'),
  },
  {  
    path: '/areamanagement',
    name: 'AreaManagement',
    component: () => import(/* webpackChunkName: "areamanagement" */ '../views/progress/AreaManagement.vue'),
  },
  {  
    path: '/photoinspection',
    name: 'PhotoInspection',
    component: () => import(/* webpackChunkName: "photoinspection" */ '../views/inspection/PhotoInspection.vue'),
  },
  {  
    path: '/pdfpage/:materialID/:materialName',
    name: 'PDFPage',
    component: () => import(/* webpackChunkName: "pdfpage" */ '../views/property/ShowPDFPage.vue'),
  },
  {  
    path: '/photoupload',
    name: 'PhotoUpload',
    component: () => import(/* webpackChunkName: "photoupload" */ '../views/inspection/PhotoUpload.vue'),
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//ナビゲーションガード。セッションストレージ内に値があるか判定
router.beforeEach((to,from,next) => {
  if(!to.path.includes('/pdfpage')) {
    if (!sessionStorage.getItem('userId') && to.path !== '/' ) {
      next('/')
    } else {
      next()  
    }
  } else {
    next()
  }
})


export default router
